import axios from "axios";

// const API_URL = "http://localhost:9000/";
const API_URL = "http://103.146.203.220:9000/";

const login = (username, password) => {
    return axios
        .post(API_URL + "user/login", {
            username,
            password,
        })
        // }, { headers: { 'Access-Control-Allow-Origin': 'http://localhost' } })
        .then((response) => {
            if (response.data.accessToken) {
                localStorage.setItem("user", JSON.stringify(response.data));
            }

            return response.data;
        });
};

const logout = () => {
    localStorage.removeItem("user");
    // localStorage.clear();
};

const getCurrentUser = () => {
    return JSON.parse(localStorage.getItem("user"));
};

export default {
    login,
    logout,
    getCurrentUser,
};